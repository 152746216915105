import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby';
import './ProjectList.scss';
import Pagination from '../Pagination';
import PostListItem from '../PostListItem'
import { PostListNavigation } from '../PostListNavigation'

export default class IndexPage extends React.Component {
  render() {
    const { posts, pageContext, categoryFilter, pathPrefix, siteMetadata} = this.props
    return (
      <section className="project-list">
        <PostListNavigation pathPrefix={pathPrefix} categoryFilter={categoryFilter} />
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts.map((slide, inedx) => {
                  return (
                    <PostListItem key={inedx} data={slide.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} />
                  )
                })}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={`/${pathPrefix}/`} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
}

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    id
    categories {
      id
      name
      slug
    }
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    featured_image_url {
      source_url
    }
  }
`
