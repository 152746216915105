import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import { Image } from '../../Image';
import './PostListItem.scss'
import { decodeEntities } from '../../../utils/helpers';

export default class ListItem extends React.Component {
  render () {
    const { data, pathPrefix, showButton } = this.props;
    const { title, slug, featured_image_url, date, categories } = data
    return (
      <div className="post-list-item">
        <div>
          <div className="image"><GatsbyLink to={`/${pathPrefix}${slug}/`} label={title}><Image src={featured_image_url} className="background" /></GatsbyLink></div>
          <div className="inner">
            <div className="meta">
              {categories.length > 0 && <span className="post-list-item-category">{decodeEntities(categories[0].name)}</span>}
              {date && <span className="date">{date}</span>}
            </div>
            <h4 className="title"><GatsbyLink to={`/${pathPrefix}${slug}/`}>{decodeEntities(title)}</GatsbyLink></h4>
            {showButton && <GatsbyLink className="action" to={`/${pathPrefix}${slug}/`}>Read more</GatsbyLink>}
          </div>
        </div>
      </div>
    )
  }
}
