import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import ProjectList from '../components/Posts/ProjectList';
import { decodeEntities } from '../utils/helpers';

export default class ProjectIndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const {wordpressPost: page = [], allWordpressWpProjects, categoryFilter, categories, wordpressWpSettings } = data;
    const { title, yoast, acf } = page ? page : { title: null, yoast: {}, acf: {} }
    const { siteTitle } = wordpressWpSettings;
    const { edges: posts } = allWordpressWpProjects

    return (
      <Layout location={location}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `Projects | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
        />
        <PageHeader headerTitle="Projects" location={location} />
        <ProjectList
          posts={posts}
          title="Latest projects"
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix="projects"
        />
      </Layout>
    )
  }
}

ProjectIndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpProjects: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title,
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressPage(slug: {eq: "projects"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    categoryFilter: allWordpressWpProjects {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
