// A sweet helper function to create pagination object
export const createPaginationObjects = (length, page, increment = 2) =>
Array.from({ length }, (_, i) => ({
  link: `/blog/page/${i + increment}/`,
  index: i + increment,
  current: page === i + increment,
}));

// More advanced pagination https://swas.io/blog/paginated-blog-post-with-gatsby-pagination/
// Generates pagination nav items
export const generateNavItems = (pages, page, pathPrefix) => {
  // First item
  let navItems = [
		{
			link: `/${pathPrefix}/`,
			index: 1,
			current: page === 1,
		},
  ];
  if (pages <= 5) {
		navItems = [
			...navItems,
			...Array.from({ length: pages - 1 }, (_, i) => ({
				link: `/${pathPrefix}/page/${i + 2}/`,
				index: i + 2,
				current: page === i + 2,
			})),
		];
	} else {
		// We have a situation where we have to show the first
		// item, three items around the current one
		// and also the last item
		/* eslint-disable no-lonely-if */
		if (page <= 3) {
			// If the current one is closer to the start
			navItems = [
				...navItems,
				...createPaginationObjects(3, page),
				{
					separator: true,
					index: 'starter-separator',
				},
				{
					link: `/${pathPrefix}/page/${pages}/`,
					index: pages,
					current: false,
				},
			];
		} else if (page > pages - 3) {
			// If the current one is closer to the last one
			navItems = [
				...navItems,
				{
					separator: true,
					index: 'finisher-separator',
				},
				...createPaginationObjects(4, page, pages - 3),
			];
		} else {
			navItems = [
				...navItems,
				{
					separator: true,
					index: 'starter-separator',
				},
				...createPaginationObjects(3, page, page - 1),
				{
					separator: true,
					index: 'finisher-separator',
				},
				{
					link: `/${pathPrefix}/page/${pages}/`,
					index: pages,
					current: false,
				},
			];
		}
  }
  return navItems;
}