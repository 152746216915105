import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import './PostListNavigation.scss'

export const PostListNavigation = (props) => {
    const { pathPrefix, categoryFilter } = props;

    const { nodes: posts } = categoryFilter

    const categoriesArray = []
    posts && posts.map(post =>
      post.categories && post.categories.map( category => {
        const thisCompare = categoriesArray.find(compare => compare.wordpress_id === category.wordpress_id)
        !thisCompare && category.slug !== 'uncategorized' && categoriesArray.push(category)
      })
    )

    return (
      <div className="post-list-navigation">
        <div className="inner">
          <ul>
            <li><GatsbyLink to={`/${pathPrefix}`} activeClassName="active">All</GatsbyLink></li>
            {categoriesArray && categoriesArray.map((category , index) => category.slug !== 'uncategorised' && (
              <li key={index}><GatsbyLink to={`/${pathPrefix}category/${category.slug}/`} activeClassName="active" dangerouslySetInnerHTML={{ __html: category.name }} /></li>
            ))}
          </ul>
        </div>
      </div>
    )
}
