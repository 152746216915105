import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import { generateNavItems } from './helpers';
import './Pagination.scss';

const Pagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null;
  }
  const {
    previousPagePath = null,
    nextPagePath = null,
    numberOfPages: pages = null,
    humanPageNumber: page,
  } = pageContext;
  // we need a check on the pathprefix being blog/ and remove the additional slash
  let navItems = []
  if (pathPrefix === "blog/") {
    navItems = generateNavItems(pages, page, pathPrefix.slice(0,-1));
  } else {
    navItems = generateNavItems(pages, page, pathPrefix);
  }
  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        <ul>
          {previousPagePath && (
            <li><GatsbyLink to={previousPagePath} className="prev">{`<`}</GatsbyLink></li>
            )}
          {navItems.map((item) => {
            return (
              <li key={item.index}>
                {item.separator ? (
                  <span className="pagination-ellipsis">
                    &hellip;
                  </span>
                ) : (
                  <GatsbyLink
                    to={item.link}
                    className={`pagination-link ${ item.current ? 'active' : '' }`}
                    activeClassName=""
                    aria-label={`Goto page ${item.index}`}
                  >
                    {item.index}
                  </GatsbyLink>
                )}
              </li>
            )
          })}
          {nextPagePath && (
            <li><GatsbyLink to={`${nextPagePath}/`} className="next">{`>`}</GatsbyLink></li>
          )}
        </ul>
      </div>
    </nav>
  )
}

export default Pagination
